export type Environment = 'int' | 'prd';

export type Link = {
  href: string;
  name?: string;
  title?: string;
  deprecation?: string;
};

export type LinkWithName = {
  href: string;
  name: string;
};

export type Costs = {
  errorReason?: string;
  totalCost: {
    USD: number;
    errorReason?: string;
  };
};

type ShipmentPlan = {
  expectedCarrier?: string;
  expectedShipDate?: string;
  enforced: boolean;
  fulfillmentLocation: {
    fulfillmentLocationId: string;
    url: string;
  };
  expectedDeliveryOption?: {
    name: string;
    lateArrival: boolean;
  };
  _links: {
    self: Link;
  };
};

export type DeliveryConfiguration = {
  quantity: number;
  deliveryRequestLink: {
    href: string;
    rel?: string;
  };
  isSample: boolean;
  shipmentPlan?: ShipmentPlan | null;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  destinationAddress?: any;
  deliveryConstraints?: any;
  /* eslint-enable */
  _links?: Link[] | null;
};

export type ItemAttribute = {
  name: string;
  value: string;
  attributeClass: string | null;
  attributeSubclass?: string | null;
};

export type Item = {
  itemId: string;
  orderId: string;
  merchantId: string;
  fulfillerId: string | null;
  globalFulfillerId: string | null;
  quantity: number;
  skuCode: string;
  orderedSkuCode: string;
  merchantProductName: string;
  localPromisedArrivalDate: string;
  deliveryConfigurations: DeliveryConfiguration[];
  documentReferenceUrl: string | null;
  variableAttributes?: ItemAttribute[];
  _links: {
    routingRequest?: LinkWithName;
    routingDecision?: LinkWithName;
    self: LinkWithName;
  };
  statuses: {
    routing?: {
      state: string;
    };
    suspended?: {
      state: string;
    };
    onHold?: {
      state: string;
    };
  };
  productAttributes?: string; // added in the decision viewer
};

export type Cause = {
  title: string;
  detail: string;
  action?: string;
};

type Problem = {
  title: string;
  detail: string;
  causes?: Cause[];
};

export type InvalidConsideredOption = {
  optionId: string;
  fulfillerId?: string;
  fulfillerName?: string;
  productAttribute?: string;
  totalCostUSD?: number;
  productConfigurationUrl: string;
  referenceId: string;
  isValid: boolean;
  problem?: Problem;
  _links: {
    fulfillmentConfiguration?: Link;
    productConfiguration?: Link;
    shipmentPlan?: Link[];
  };
  _uiLinks?: {
    productManager?: {
      href: string;
      version: string;
    };
    fulfillmentConfigurationLink?: {
      href: string;
      version: number;
    };
  };
};

export type ValidConsideredOption = {
  fulfillerId: string;
  optionId: string;
  productConfigurationUrl: string;
  rank: string;
  referenceId: string;
  problem?: Problem;
};

export type ConsideredOptionPriceData = {
  optionId?: string;
  productionCost?: ProductionCost;
  shippingCost?: {
    errorReason?: string;
  };
};

export type ProductionCost = {
  productionCostUSD?: number;
  productionCostLocal?: number;
  productionCostCurrencyCode?: string;
};

export type Option = {
  productConfigurationUrl?: string;
  fulfillerName?: string; // added from the baseFulfillmentConfiguration JSON for an option or fulfiller identity service
  productAttribute?: string; // added from the Product Introduction service
  totalCostUSD?: number;
  productName: string; // we get this from getMcpProductByMcpSku()
  fulfillmentConfiguration?: string;
  optionId: string;
  isValid: boolean;
  isSelected?: boolean;
  _links: {
    fulfillmentConfiguration?: Link;
    productConfiguration?: Link;
    shipmentPlan?: Link[];
  };
  _embedded?: {
    deliveryFeasibility?: {
      price?: {
        combined?: {
          currency: string;
          total: number;
        };
      };
    };
  };
  problem?: Problem;
  referenceId: string;
  fulfillerId: string;
  rank?: string;
  _uiLinks?: {
    productManager?: {
      href: string;
      version: string;
    };
    fulfillmentConfigurationLink?: {
      href: string;
      version: number;
    };
  };
};

export const isValidOption = (option: Option | InvalidConsideredOption): option is Option => {
  return (option as Option).isValid !== undefined && (option as Option).isValid !== false;
};

export type FailedDecision = {
  decisionId: string;
  itemId: string;
  itemUrl?: string;
  strategyUrl?: string;
  consideredOptions?: InvalidConsideredOption[];
  problem: Problem;
  createdTime: string;
  _links?: {
    routingRequest: LinkWithName;
    routingConfiguration: LinkWithName;
    item?: LinkWithName;
    itemRoutingRequest?: LinkWithName;
    self?: LinkWithName;
    networkOperator: LinkWithName;
  };
};

export type SuccessfulDecision = {
  decisionId: string;
  itemId: string;
  itemUrl?: string;
  strategyUrl?: string;
  chosenOptionId: string;
  consideredOptions?: Option[];
  consideredOptionPriceData: ConsideredOptionPriceData[];
  selectedOption: Option;
  createdTime: string;
  _links: {
    routingRequest: LinkWithName;
    routingConfiguration: LinkWithName;
    item: LinkWithName;
    itemRoutingRequest: LinkWithName;
    self: LinkWithName;
    networkOperator: LinkWithName;
  };
};

export type RoutingDecision = SuccessfulDecision | FailedDecision;

export const isSuccessfulDecision = (decision: SuccessfulDecision | FailedDecision): decision is SuccessfulDecision => {
  return (decision as SuccessfulDecision).selectedOption !== undefined;
};

export const isFailedDecision = (
  decision: SuccessfulDecision | FailedDecision | undefined,
): decision is FailedDecision => {
  return (decision as FailedDecision).problem !== undefined;
};

export type ExistingPick = {
  productConfigUrl: string;
  fulfillerId: string;
  fulfillerName?: string;
  itemId: string;
  orderId: string;
  referenceId: string;
  fulfillmentConfiguration: string | undefined;
};

export type StrategyData = {
  name: string;
  id: string;
};

export type OptionEqualityHandler = (option1: Option | ExistingPick, option2: Option | ExistingPick) => boolean;

type ProductVariable = {
  attributeKey: string;
  attributeValue: string;
};

type ProductAttribute = {
  class: string;
  unitOfMeasure?: string;
  attributeKey: string;
  attributeValue: string;
};

type RelatedResource = {
  schemaVersion?: string;
  href: string;
};

export type PMV1ProductConfigResponse = {
  validationUri: string;
  attributes: ProductAttribute[];
  productStatus: string;
  relatedResources: {
    fulfillmentConfigurations: RelatedResource[];
    category: RelatedResource[];
    calculatedSurfaceSet: RelatedResource[];
  };
  createdAt: string;
  mcpSku: string;
  variables: ProductVariable[];
};

export type PMV2ProductConfigResponse = PMV1ProductConfigResponse & {
  relatedResources: PMV1ProductConfigResponse['relatedResources'] & {
    category: RelatedResource[];
  };
  productVersion: string;
};

type StatusChange = {
  date: string;
  status: string;
};

export type RoutingRequestBodyItem = {
  itemId?: string;
  _embedded?: {
    ownedItem: {
      _links: {
        productConfiguration: Link;
      };
    };
  };
  _links?: {
    networkOperator: Link;
  };
  networkOperator?: Link;
  initiatedDecisionName?: string;
  self: string;
  initiatedDecision: string;
  originalItem?: Link;
};

export type OwnedItem = {
  _links: {
    productConfiguration: Link;
  };
};

type RoutingRequestBody = {
  callback: string;
  metadata: {
    routingDepth: number;
  };
  items: RoutingRequestBodyItem[];
  merchantId: string;
  _links: {
    item: Link[];
  };
  _embedded?: {
    itemRoutingRequest: [
      {
        _links: {
          item: Link;
          initiatedDecision: Link;
        };
        _embedded: {
          ownedItem: OwnedItem;
        };
      },
    ];
  };
};

export type RoutingRequestResponse = {
  statusChanges: StatusChange[];
  routingRequestHash: string;
  buyer: string;
  modifiedAt: string;
  requestBody: RoutingRequestBody;
  chosenRoutingDecisionLockId: string;
  attemptsCount: number;
  buyerType: string;
  correlationData: {
    leaf: string;
    parent: string;
    root: string;
  };
  originalRequestBody: RoutingRequestBody;
  completedDateTime: string;
  requestStatus: string;
  _links: {
    networkOperator: Link;
    retry: Link;
    clear: Link;
    self: Link;
  };
  chosenRoutingDecision: {
    [key: string]: {
      decisionId: string;
      item: {
        itemId: string;
      };
    };
  };
  createdDateTime: string;
  routingRequestId: string;
  timeToLive: number;
  _embedded: {
    itemRoutingRequest: [
      {
        _links: {
          item: Link;
          networkOperator: Link;
          initiatedDecision: Link;
          originalItem: Link;
        };
        _embedded: {
          ownedItem: OwnedItem;
        };
      },
    ];
    routingResult: [
      {
        _links: {
          routingDecision: Link;
          item: Link;
        };
      },
    ];
  };
};

export type ItemRoutingRequestResponse = {
  _links: {
    item: LinkWithName;
    networkOperator: LinkWithName;
    initiatedDecision: LinkWithName;
    originalItem: LinkWithName;
    fulfillmentOptions: LinkWithName;
    routingConfiguration: LinkWithName;
  };
  _embedded: {
    ownedItem: OwnedItem;
  };
};

export type FulfillerIdentityResponse = {
  name: string;
  fulfillerId: string;
  internalFulfillerId: number;
  modifiedAt: string;
  links: {
    account: Link;
    fulfillerCoamAdminGroup: Link;
    fulfillerLogo: Link;
    self: Link;
    fulfillmentLocations: Link;
    fulfillerContacts: Link;
  };
  email: string;
  phone: string;
  language: string;
};

export type FulfillmentConfigurationsResponse = {
  fulfillmentConfigurationId: string;
  name: string;
  isSynthetic: boolean;
  modifiedAt: string;
  isArchived: boolean;
  versionCount: number;
  owner: string;
  _links: {
    self: Link;
    seller: Link;
    versions: Link;
    currentVersion: Link;
  };
};
