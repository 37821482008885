import { useMutation, useQuery } from 'react-query';
import {
  RetryRoutingRequestParams,
  GetRoutingRequestParams,
  getRoutingRequest,
  getItemRoutingRequest,
  retryRoutingRequest,
} from '../clients/requestManager';
import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';
import { ItemRoutingRequestResponse, RoutingRequestResponse } from '../types';

export const useRetryRoutingRequest = ({
  onSuccess,
  onError,
  onMutate,
  onSettled,
}: {
  onSuccess: () => void;
  onError: (error: Error) => void;
  onMutate: () => void;
  onSettled: () => void;
}) =>
  useMutation((props: RetryRoutingRequestParams) => retryRoutingRequest(props), {
    onSuccess,
    onError,
    onMutate,
    onSettled,
  });

export const useGetRoutingRequest = (props: GetRoutingRequestParams) => {
  return useQuery<{ routingRequest: RoutingRequestResponse | undefined }, RoutingUIError | Error>(
    ['getRoutingRequest', props.routingRequestUrl],
    () => getRoutingRequest(props),
    {
      enabled: props.enabled,
    },
  );
};

export const useGetItemRoutingRequest = (props: GetRoutingRequestParams) => {
  return useQuery<{ routingRequest: ItemRoutingRequestResponse | undefined }, RoutingUIError | Error>(
    ['getRoutingRequest', props.routingRequestUrl],
    () => getItemRoutingRequest(props),
    {
      enabled: props.enabled,
    },
  );
};
