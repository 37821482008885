import { useQuery } from 'react-query';
import { GetProductConfigurationParams, getProductConfiguration } from '../clients/productConfiguration';
import { PMV1ProductConfigResponse, PMV2ProductConfigResponse } from '../types';

type UseGetProductConfigurationProps = GetProductConfigurationParams & {
  enabled?: boolean;
};

export const useGetProductConfiguration = (props: UseGetProductConfigurationProps) => {
  return useQuery<{ productConfiguration: PMV1ProductConfigResponse | PMV2ProductConfigResponse | undefined }, Error>(
    ['getProductConfiguration', props.option],
    () => getProductConfiguration(props),
    {
      enabled: props.enabled,
    },
  );
};
